h1 {
  font-size: 20px !important;
  font-weight: bold !important;
}

h2 {
  font-size: 18px !important;
  font-weight: bold !important;
}

h3 {
  font-size: 16px !important;
  font-weight: bold !important;
}

h4 {
  font-size: 14px !important;
  font-weight: bold !important;
}

.semiHead-1 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.semiHead-2 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.semiHead-3 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.semiHead-4 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.semiHead-5 {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.mediumHead-1 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.mediumHead-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mediumHead-3 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.regularContent-1 {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.regularContent-2 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.regularContent-3 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.regularContent-4 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.boldContent-1 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 4px;
  /* Width of the vertical scrollbar */
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 6px;
  /* Roundness of the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}

/* Horizontal scrollbar */
::-webkit-scrollbar-x {
  height: 4px !important;
  /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-x-track {
  background: #f1f1f1;
  /* Color of the track */
}

::-webkit-scrollbar-x-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 6px;
  /* Roundness of the scrollbar handle */
}

::-webkit-scrollbar-x-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}

.card-company {
  position: relative;
  width: 500px;
  background-color: #283142;
  text-align: center;
}

.card-company .semicircle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  /* Adjust the height as needed */
  background-image: linear-gradient(to right, #444d4e, #6a8f8f);
  border-radius: 0 0 50px 50px;
  /* To create a semicircle at the bottom */
  z-index: 2;
}

.card-company .title-total {
  position: relative;
  z-index: 3;
}

.card-company::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

.card-company::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

/* .chat .chat-content .chat-content-body .msg {
  display: block !important;
} */

.msg-recipient .bubble {
  float: left !important;
}

.msg-sent .bubble {
  float: right !important;
}

.profile-card .ant-descriptions-item-content,
.profile-card .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 10px 10px;
}

.profile-card .ant-descriptions-bordered .ant-descriptions-view {
  padding: 2px;
}

.profile-card .ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}

.profile-card .ant-card {
  margin: 0px;
}

.invoice-detail .ant-table {
  background-color: unset;
}

.attendance-report .anticon svg,
output {
  display: inline-block;
  font-size: 15px;
}

input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #b4bed2;
  font-size: 14px;
  line-height: 1.5;
  background-color: #283142;
  background-image: none;
  border: 1px solid #4d5b75;
  /* border-radius: 0.625rem; */
  transition: all .3s;
}

input:not {
  touch-action: manipulation;
  background: #283142;
  border-color: #ff6b72;
}

:focus-visible {
  outline: unset;
}

input:hover {
  border-color: #bc404f !important;
  border-right-width: 1px;
}

.manage-class-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

.ant-input-affix-wrapper {
  border-radius: 50px !important;
}

.ant-input {
  border-radius: 10px;
}

.ant-picker {
  border-radius: 10px !important;
}

.invoice-chart .ant-card-bordered {
  border: none !important;
}


.scrollable-content::-webkit-scrollbar {
  width: 6px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #888;
  border-radius: 3px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #1b2531;
  border-radius: 3px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ant-tabs-content-holder::-webkit-scrollbar {
  width: 6px;
}

.ant-tabs-content-holder::-webkit-scrollbar-track {
  background-color: #888;
  border-radius: 3px;
}

.ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background-color: #1b2531;
  border-radius: 3px;
}

.ant-tabs-content-holder::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.dash_calendar .ant-picker-calendar-mini {
  height: 379px;
}

.drawer-dash {
  height: 355px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  text-align: center;
  margin: 2px;
}

.drawer-dash-monthly {
  height: 330px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  text-align: center;
  margin: 2px;
}

.birthdays_dashboard {
  height: 275px;
}

.dashboard-select {
  width: 100%;
  text-align: center;
  float: right;
}

.main-drawer {
  position: absolute;
}

.dashboard-birthdays {
  width: 100px;
  height: 100px;
}

.drawer-dash-monthly .apexcharts-legend-series {
  text-align: left;
}

.ant-tag {
  font-size: 13px !important;
}

.logo_reset {
  height: 75px;
  width: 200px;
}

.ant-statistic-title {
  font-size: 14px;
}

.assignment {
  text-align: left !important;
}

.assign-task {
  float: right !important;
}

.panel-text {
  width: 100%;
}

.assign-tag {
  float: right;
}

.assign-dropdowns {
  text-align: right;
}

.notify-card {
  border-radius: 0;
}

.app-header .nav-right {
  margin-left: unset !important;
}

.nav-center {
  display: flex;
  align-items: center;
}

.menu-badge {
  float: right;
  margin-top: 10px;
}

.menu-badge .ant-badge-count {
  background: #bc404f !important;
}

.card-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none;
}

.language-color a.text-gray:focus,
a.text-gray:hover {
  color: unset !important;
}

.ant-input-number {
  border-radius: 10px;
}

/* .teacher-container {
  display: flex;
  text-align: left;
} */

.profile-image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
}

.teacher-name {
  position: absolute;
  top: -100%;
  left: -10%;
  transform: translateX(-50%);
  background-color: #0f0f0f;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 2000;
  display: none;
  white-space: nowrap;
}

/*
.teacher-container:hover .teacher-name {
  display: block; 
} */
.ant-layout-sider-children>*:nth-child(3) {
  margin-right: 0 !important;
}

.number-error .ant-form-item-explain-error {
  margin-top: -20px;
}

.search-results .ant-tabs {
  /* width: 100% !important; */
  /* min-width: max-content !important; */
}

.fc .fc-button {
  border-radius: 10px !important;
  margin: 0px 5px;
}

.guardian-image .ant-upload {
  width: 100%;
}

.student-image .ant-list-item-meta-avatar {
  /* border: 1px solid gray; */
  border-radius: 50px;
  padding: 5px;
  height: 40px;
  width: 40px;
}

.signature-label {
  margin-top: 16px;
  font-weight: bold;
}

.signature-box {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  cursor: url('../public/img/icons/pen.png'), auto;
}

.disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grade-book-select .ant-tabs-top>.ant-tabs-nav {
  margin: 0px !important;
}

.grade-top-card {
  background-color: #6B84AD;
  height: 125px;
  padding: 5px;
}

.grade-top-card h2 {
  color: white;
}

.grade-top-card h5 {
  color: white;
}

.grade-progress .ant-progress-inner {
  width: 200px !important;
  height: 200px !important;
  font-size: 45px !important;
  font-weight: 900;
}

.grade-strength h3 {
  color: white !important;
}

.grade-strength h5 {
  color: white !important;
}

.grade-strength span {
  color: white !important;
}

.incident-tag {
  border: 1px solid #B91010;
  padding: 6px;
  background-color: #f7e7e7;
  border-radius: 50px;
  color: #B91010;
  font-weight: 700;
}

.up-incident-tag {
  border: 1px solid #2fb910;
  padding: 6px;
  background-color: #e7f7e7;
  border-radius: 50px;
  color: #28b910;
  font-weight: 700;
}

.incident-card {
  height: 400px;
}

.incident-summary h4 {
  color: #B91010;
}

.incident-summary .label {
  color: #3E3E3E;
  font-size: 16px;
  font-weight: 400;
}

.incident-summary .description {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

/* Invoice Dashboard */
.invoice-dashboard h4 {
  font-weight: 600;
  font-size: 14px;
}

.invoice-dashboard h1 {
  font-weight: 500;
  font-size: 28px;
}

.invoice-dashboard .compared-week {
  font-weight: 400;
  font-size: 12px;
  color: #939393;
}

.invoice-dashboard .compare-percent {
  color: #378A3F;
  font-weight: 500;
  font-size: 14px;
}

.invoice-dashboard .divided {
  border-bottom-width: 2px;
  display: block;
  width: 34px;
  margin-bottom: 2px;
}

.invoice-dashboard .divide-1 {
  border-bottom: 2px solid #3CC526;
}

.invoice-dashboard .divide-2 {
  border-bottom: 2px solid #F66B1D;
}

.invoice-dashboard .divide-3 {
  border-bottom: 2px solid #FFB931;
}

.invoice-dashboard .divide-4 {
  border-bottom: 2px solid #3177FF;
}

.invoices-card {
  height: 320px;
}

.invoice-card-2 {
  height: 320px;
}

.invoices-card h3 {
  font-size: 20px;
  font-weight: 600;
}

.purchase-form .ant-form label {
  width: 115px !important;
  text-align: center !important;
}

.all-filter {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}

.all-filter .card {
  border-radius: 0px !important;
}

.all-charges .box {
  width: 16px;
  height: 16px;
  border-radius: 99px;
  margin-right: 10px;
  margin-top: 7px;
  display: flex;
}

.all-charges .box-1 {
  background-color: #F9DE53;
}

.all-charges .box-2 {
  background-color: #036AE3;
}

.all-charges .box-3 {
  background-color: #96D544;
}

.all-charges button {
  background-color: #F5F5F5;
  padding: 8px 12px 8px 12px;
  border-radius: 8.97px;
  color: #000000;
}

.all-charges .card-1 {
  height: 400px;
}

.all-charges .card-2 {
  height: 400px;
}

/************************** BUDGET ALLOCATION **************************/
.total-budget {
  border-radius: 75px;
  width: 12px;
  height: 6px;
  background-color: #338417;
}

.consume-budget {
  border-radius: 75px;
  width: 12px;
  height: 6px;
  background-color: #21B252;
}

.balance-budget {
  border-radius: 75px;
  width: 12px;
  height: 6px;
  background-color: #7FD74B;
}

.ant-progress-bg {
  background: conic-gradient(#338417 0% 34.5%,
      /* Total budget */
      #000000 34.5% 34.6%,
      /* Black border */
      #21B252 35% 64.5%,
      /* Consumed budget */
      #000000 64.5% 64.6%,
      /* Black border */
      #7FD74B 65% 100%
      /* Remaining balance */
    );
}

.black-border {
  background-color: #000000;
  width: 12px;
  height: 12px;
}

.lesson-card {
  text-align: left;
}

.lesson-time {
  font-size: 12px;
  font-weight: 700;
  color: #3C3C3C;
}

.lesson-course {
  font-size: 12px;
  font-weight: 400;
  color: #3C3C3C;
}

.lesson-card .ant-timeline-item-head {
  width: 4px !important;
  height: 20px !important;
  margin-top: -6px !important;
}

.table-footer-display>tr>th {
  text-align: center;
  /* Center align the text in the header cells */
}

.event-container .ant-badge-status-dot {
  width: 25px;
  height: 25px;
}

.ant-tabs-tab-schedule .ant-tabs-tab {
  display: block;
}